var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "breadcrumb-fontcolor", attrs: { separator: "/" } },
    [_c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.$t(_vm.title)))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }