<template>
  <div class="main-left-tree-content">
    <el-input
      class="main-left-tree-filter mgt-10"
      :placeholder="$t('lang_enter_keywords_to_filter')"
      clearable
      v-model="filterText"
    ></el-input>
    <el-tree
      class="main-left-tree-instance"
      @node-click="searchList"
      :filter-node-method="filterNode"
      :expand-on-click-node="false"
      :data="searchTreeData"
      default-expand-all
      :props="{
        children: 'children'
      }"
      :highlight-current="true"
      ref="appTreeRef"
      node-key="id"
    >
      <template v-slot="{ data }">
        <span class="custom-tree-node tree-span">
          <span class="custom-tree-node-title">{{ $t(data.text) }}</span>
        </span>
      </template>
    </el-tree>
  </div>
</template>

<script>
import AppTypeApi from '@/api/application/AppTypeApi';

export default {
  data() {
    return {
      applicationIds: null,

      filterText: '',
      // 搜索树数据
      searchTreeData: [],
      // 搜索表单
      searchData: {
        // 应用类型id
        applicationIds: [],
        // 流程code
        processCode: '',
      }
    };
  },
  watch: {
    filterText(val) {
      this.$refs.appTreeRef.filter(val);
    }
  },
  computed: {
    _user() {
      return this.$store.getters.user;
    }
  },
  created() {
    this.getTreeData();
  },
  methods: {
    //获取树数据
    getTreeData() {
      AppTypeApi.applicationLinkQueryCodeTree({ linkType: 2, type: 0, scopeUser: this._user.id })
        .then(res => {
          if (res.code == '1') {
            this.searchTreeData = setParentId(res.data, '-1');
          } else {
          }
        })
        .finally(() => {});

        // 设置父级id
        function setParentId (arr, parentId) {
          if (arr && arr.length > 0) {
            arr.forEach(item => {
              item.parentId = parentId;
              if (item.children && item.children.length > 0) {
                item.children = setParentId(item.children, item.id);
              }
            })
          }
          return arr;
        }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.text.indexOf(value) !== -1;
    },

    // 点击搜索
    searchList(val, node) {
      if (val.children && val.children.length) {
        this.clearCurrentKey();
        return;
      }
      this.searchData.applicationIds = [val.parentId];
      this.searchData.processCode = val.code;
      this.$emit('topSearch', this.searchData);
    },
    // 重置
    restSearch() {
      this.$emit('topReset');
    },
    clearCurrentKey() {
      this.$refs.appTreeRef.setCurrentKey(null);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-top {
  position: absolute;
  width: 80%;
  right: -2vw;
  top: 1.167vw;
  /deep/ .vue-treeselect__input {
    height: 38px;
  }
  /deep/ .el-button {
    height: 40px;
  }
}
</style>
