var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-left-tree-content" },
    [
      _c("el-input", {
        staticClass: "main-left-tree-filter mgt-10",
        attrs: {
          placeholder: _vm.$t("lang_enter_keywords_to_filter"),
          clearable: "",
        },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c("el-tree", {
        ref: "appTreeRef",
        staticClass: "main-left-tree-instance",
        attrs: {
          "filter-node-method": _vm.filterNode,
          "expand-on-click-node": false,
          data: _vm.searchTreeData,
          "default-expand-all": "",
          props: {
            children: "children",
          },
          "highlight-current": true,
          "node-key": "id",
        },
        on: { "node-click": _vm.searchList },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var data = ref.data
              return [
                _c("span", { staticClass: "custom-tree-node tree-span" }, [
                  _c("span", { staticClass: "custom-tree-node-title" }, [
                    _vm._v(_vm._s(_vm.$t(data.text))),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }