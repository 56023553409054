<template>
  <el-breadcrumb separator="/" class="breadcrumb-fontcolor">
    <el-breadcrumb-item>{{ $t(title) }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'Location',
  props: {
    name: ''
  },
  data() {
    return {
      title: ''
    };
  },
  methods: {},
  created() {
    if (!this.name) {
      this.title = this.$route.meta.title;
    } else {
      this.title = this.name;
    }
  },
  watch: {
    $route(to, from) {}
  }
};
</script>

<style lang='scss' scoped>
.el-breadcrumb {
  font-size: var(--rootFontSize2);
  line-height: 40px;

  /deep/ .el-breadcrumb__inner {
    font-weight: bold;
    color: #0270c1;

    &:hover {
      font-weight: bold;
      color: #0270c1;
    }
  }
}
</style>

